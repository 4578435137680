<template>
  <div>
    <!-- 让成长在次发生 -->
    <div class="develop">
      <div class="develop-center">
        <div class="develop-title">
          <img src="https://qncdn.uni-sheep.com/s/Group%20952%20%281%29%281%29.png" alt="">
        </div>
        <div>
          <button class="developlice family-about" @click="ImmeMethod()">立即咨询</button>
        </div>
      </div>
    </div>
    <!-- 脚步部分 -->
    <div class="footerbox">
      <div class="centebox">
        <!-- <div class="center-first">
          <div>关于我们</div>
          <div>公司简介</div>
          <div>发展历程</div>
        </div>
        <div class="center-first">
          <div>快捷入口</div>
          <div>奶牛方案</div>
          <div>肉牛方案</div>
          <div>奶羊方案</div>
          <div>肉羊方案</div>
        </div> -->
        <div class="center-three">
          <div class=" family-title ">联系我们</div>
          <div class="family-about">公司地址：内蒙古呼和浩特赛罕区乐邦联合C6621</div>
          <div class="family-about">
            公司地址：江苏省苏州市姑苏区蓝文化创意产业园胥江路235号6-610
          </div>
          <div class="family-about">公司地址：北京市海淀区高梁桥斜街40号13号楼1层130室</div>
          <div class="family-about">联系电话：+0471-5613511</div>
          <div class="family-about">合作邮箱：nmgzmtc2023@163.com</div>
        </div>
        <div class="center-four">
          <div class="center-ma">
            <div class="center-maimg">
              <img src="https://qncdn.uni-sheep.com/s/Group%201006.png" alt="" />
            </div>
          </div>
          <div class="center-ma">
            <div class="center-maimg">
              <img src="https://qncdn.uni-sheep.com/s/Group%201007.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {};
  },
  methods: {
    // 触发首页的自定义事件
    ImmeMethod() {
      this.$emit("Immediately");
    },
  },
};
</script>
<style scoped>
/* 让成长在次发生 */
.develop {
  width: 100%;
  height: 14rem;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background: url("https://qncdn.uni-sheep.com/s/%E7%AB%8B%E5%8D%B3%E5%92%A8%E8%AF%A2%281%29.png") center center;
  background-size: cover;
}
.develop-center {
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid #000; */
}

.develop-title {
  width: 25.5rem;
  height: 5.1694rem;
  /* border: 1px solid #000; */
}
.developlice {
  width: 12rem;
  height: 3rem;
  border: 1px solid white;
  color: #2488de;
  border-radius: 2rem;
  background: white;
  cursor: pointer;
  font-weight: 800;
  font-size: 1.3rem;
}
.developlice:hover {
  opacity: 66;
  border: none;
  background-color: rgb(200, 221, 237);
}
/* 脚步 */
.footerbox {
  width: 100%;
  height: 28rem;
  /* // border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  /* margin-top: 3rem; */
}
.centebox {
  width: 1460px;
  height: 18rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  /* // border: 1px solid #000; */
}
.center-first {
  width: 6rem;
  /* // height: 14rem; */
  line-height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  /* // border: 1px solid #000; */
}
.center-first div:nth-child(1) {
  font-size: 1.3rem;
  font-weight: 600;
}
.center-three {
  width: 40rem;
  /* // height: 14rem; */
  line-height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  color: white;
   /* border: 1px solid white; */
}
.center-three div:nth-child(1) {
  font-size: 1.3rem;
  font-weight: 600;
}
.center-four {
  width: 20rem;
  height: 10rem;
  display: flex;
  justify-content: space-around;
  /* // border: 1px solid #000; */
}
.center-ma {
  width: 6.5rem;
  height: 8rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.center-maimg {
  width: 7.5rem;
  height: 9rem;
}
.center-maimg img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>
