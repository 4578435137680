var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"seebabox"},[_vm._m(0),_c('div',{staticClass:"service-box"},[(_vm.servicedisplay)?_c('div',{staticClass:"service-titbox"},[_c('div',{staticClass:"service-futit family-title"},[_vm._v("服务体系")]),_vm._m(1)]):_vm._e(),_vm._m(2),_vm._m(3)]),(_vm.flowpathdisplay)?_c('div',{staticClass:"flowpath-box"},[_vm._m(4)]):_vm._e(),_c('div',{staticClass:"evaluation-box"},[(_vm.evaluationdisplay)?_c('div',{staticClass:"evaluation-cont"},[_vm._m(5),_c('div',{staticClass:"evaluation-bottom"},[_c('div',{staticClass:"evaleftbutton",on:{"click":_vm.prevImage}},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20341%20%281%29%281%29.png","alt":""}})]),_c('div',{staticClass:"eva-centerbox"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('img',{key:_vm.currentImage,attrs:{"src":_vm.currentImage,"alt":"Slideshow Image"}})])],1),_c('div',{staticClass:"evaleftbutton",on:{"click":_vm.nextImage}},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20342%20%281%29%281%29.png","alt":""}})])])]):_vm._e()]),_c('div',{staticClass:"standards-box"},[(_vm.standardsdisplay)?_c('div',{staticClass:"standards-cont"},[_vm._m(6),_vm._m(7)]):_vm._e()]),_c('public-footer',{on:{"Immediately":_vm.Immediately}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seekbox"},[_c('div',{staticClass:"seek-liftbox"},[_c('div',{staticClass:"bt-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20359%281%29.png","alt":""}})]),_c('div',{staticClass:"seek-content"},[_c('div',{staticClass:"seek-title family-title"},[_vm._v("奥宸咨询")]),_c('div',{staticClass:"seek-nei family-about"},[_vm._v(" 奥宸咨询历时数年在羊产业学习沉淀，针对目前规模化集约化牧场，生产效率低，盈利能力差，缺乏标准化、系统化、科学化、信息化等问题，制定了符合国内牧场的咨询服务体系。能够快速的帮助牧场发现问题，改进问题。稳定持续的输出各项技术服务，为牧场打造适合自己的管理方式及生产标准，带动牧场迅速盈利。 奥宸咨询汇集了行业内各路精英，目前拥有数十人组成的专业化顾问团队，团队成员除了拥有硕士、学士等专业学位外，还拥有10年以上牧场实际生产管理及服务经验。另外还有各大院校特聘专家及业内资深人士共同携手组建了以牧场数据为核心，以标准流程建设为基准、现场评估为根本的新型服务模式。 ")])]),_c('div',{staticClass:"seek-limg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Vector%2040%20%281%29%281%29.png","alt":""}})])]),_c('div',{staticClass:"seek-rightbox"},[_c('div',{staticClass:"seekimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20357%20%281%29%281%29.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20323%281%29.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scriptbox"},[_c('div',{staticClass:"script-content family-about"},[_vm._v(" 奥宸咨询打造双流服务体系，技术流及数据流双标服务。旨在全面提升规模化牧场养殖生产效率。在现代化的管理理念和先进的管理工具的支撑下，构建集牧场现场技术指导、长期服务跟踪、核心业务流程再造和生产经营数据分析、牧场改进方案推进等一系列的服务项目和产品。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-box"},[_c('div',{staticClass:"system-leftbox"},[_c('div',{staticClass:"system-top"},[_c('div',{staticClass:"system-imgbox"},[_c('div',{staticClass:"system-imon"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/image%20106%281%29.png","alt":""}})]),_c('div',{staticClass:"system-tin family-picturetext"},[_vm._v(" 牧场技术服务体系 ")])]),_c('div',{staticClass:"system-cont family-about"},[_vm._v(" 以营养调控和营养工程技术为核心，帮助牧场梳理和搭建符合牧场实际情况的技术和管理体系；为牧场持续的引进先进的管理方法和技术；利用自牧天成牧场数据化管理平台为牧场提供量化的，精细化的分析，从而实现节本增效。 ")])]),_c('div',{staticClass:"system-bottom"},[_c('div',{staticClass:"system-boimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20356%281%29.png","alt":""}})])])]),_c('div',{staticClass:"system-rightbox"},[_c('div',{staticClass:"system-top"},[_c('div',{staticClass:"system-imgbox"},[_c('div',{staticClass:"system-imon"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/image%20107%281%29.png","alt":""}})]),_c('div',{staticClass:"system-tin family-picturetext"},[_vm._v(" 数据流程服务体系 ")])]),_c('div',{staticClass:"system-cont family-about"},[_vm._v(" 指数据顾问将我们制定的业务流程因时制宜，因地制宜地导入到各个客户牧场。通过流程设计和管理，来约束和指导牧场每日的工作安排；同时根据数据分析和现场调研，发现牧场的问题；协助牧场跟踪落实，并且利用数据分析，跟踪量化的结果变化，提出具体的服务内容和项目。 ")])]),_c('div',{staticClass:"system-bottom"},[_c('div',{staticClass:"system-boimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20357%20%282%29%281%29.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flowpath-content"},[_c('div',{staticClass:"flowpath-titbox"},[_c('div',{staticClass:"flowpath-futit family-title"},[_vm._v("服务流程")]),_c('div',{staticClass:"flowpath-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20323%281%29.png","alt":""}})])]),_c('div',{staticClass:"flowpath-miaoshu family-about"},[_vm._v(" 咨询服务先从基础调研开始，在完成牧场硬件设施、羊群结构、日粮结构和营养指标等基础性调研后，同时开始完成牧场的业务流程再造和现场技术指导。我们以技术提升为本，以流程提升带动管理提升为纲，使得牧场从短期效益到长期发展都得到满足。我们会为每一个牧场，建立一个专业的固定团队，对客户牧场进行长期的跟踪服务，有效保证了所有服务方案的针对性和有效性。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluation-top"},[_c('div',{staticClass:"evaluation-bao"},[_c('div',{staticClass:"evaluation-futit family-title"},[_vm._v("效果评估")]),_c('div',{staticClass:"evaluation-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20323%281%29.png","alt":""}})])]),_c('div',{staticClass:"evaluation-name family-text"},[_vm._v(" 让客户盈利是咨询服务的主要目标 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-titbox"},[_c('div',{staticClass:"standards-futit family-title"},[_vm._v("收费标准")]),_c('div',{staticClass:"standards-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/Group%20323%281%29.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"standards-center"},[_c('div',{staticClass:"standards-left"},[_c('div',{staticClass:"standards-img"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/08983e46d05d2b02882a81a85631bcec54387e9120a21-oPcclH_fw1200%201%281%29.png","alt":""}})]),_c('div',{staticClass:"standards-ti family-picturetext"},[_vm._v(" 牧场技术服务体系 ")]),_c('div',{staticClass:"standards-fu family-text"},[_vm._v(" 服务费为100元/只/年；不但全面包含数据化服务，每周例会，同时包括每月一次的专家登门服务 ")])]),_c('div',{staticClass:"standards-right"},[_c('div',{staticClass:"standards-rimg"},[_c('img',{attrs:{"src":"https://qncdn.uni-sheep.com/s/image%2098%281%29.png","alt":""}})]),_c('div',{staticClass:"standards-rti family-picturetext"},[_vm._v(" 数据流程服务体系 ")]),_c('div',{staticClass:"standards-rfu family-text"},[_vm._v(" 服务费为50元/只/年，旨在帮助客户建立起相关的业务流程管理，制定合理的业务考核指标，安装导入牧场管理系统，并且每月提供专业的分析报表 ")])])])
}]

export { render, staticRenderFns }