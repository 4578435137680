<template>
  <div>
    <!-- 奥宸咨询 -->
    <div class="seebabox">
      <div class="seekbox">
        <div class="seek-liftbox">
          <div class="bt-img">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%20359%281%29.png"
              alt=""
            />
          </div>
          <div class="seek-content">
            <div class="seek-title family-title">奥宸咨询</div>
            <div class="seek-nei family-about">
              奥宸咨询历时数年在羊产业学习沉淀，针对目前规模化集约化牧场，生产效率低，盈利能力差，缺乏标准化、系统化、科学化、信息化等问题，制定了符合国内牧场的咨询服务体系。能够快速的帮助牧场发现问题，改进问题。稳定持续的输出各项技术服务，为牧场打造适合自己的管理方式及生产标准，带动牧场迅速盈利。
              奥宸咨询汇集了行业内各路精英，目前拥有数十人组成的专业化顾问团队，团队成员除了拥有硕士、学士等专业学位外，还拥有10年以上牧场实际生产管理及服务经验。另外还有各大院校特聘专家及业内资深人士共同携手组建了以牧场数据为核心，以标准流程建设为基准、现场评估为根本的新型服务模式。
            </div>
          </div>
          <div class="seek-limg">
            <img
              src="https://qncdn.uni-sheep.com/s/Vector%2040%20%281%29%281%29.png"
              alt=""
            />
          </div>
        </div>
        <div class="seek-rightbox">
          <div class="seekimg">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%20357%20%281%29%281%29.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <!-- 服务体系 -->
      <div class="service-box">
        <div class="service-titbox" v-if="servicedisplay">
          <div class="service-futit family-title">服务体系</div>
          <div class="service-img">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%20323%281%29.png"
              alt=""
            />
          </div>
        </div>
        <div class="scriptbox">
          <div class="script-content family-about">
            奥宸咨询打造双流服务体系，技术流及数据流双标服务。旨在全面提升规模化牧场养殖生产效率。在现代化的管理理念和先进的管理工具的支撑下，构建集牧场现场技术指导、长期服务跟踪、核心业务流程再造和生产经营数据分析、牧场改进方案推进等一系列的服务项目和产品。
          </div>
        </div>
        <div class="system-box">
          <div class="system-leftbox">
            <div class="system-top">
              <div class="system-imgbox">
                <div class="system-imon">
                  <img
                    src="https://qncdn.uni-sheep.com/s/image%20106%281%29.png"
                    alt=""
                  />
                </div>
                <div class="system-tin family-picturetext">
                  牧场技术服务体系
                </div>
              </div>
              <div class="system-cont family-about">
                以营养调控和营养工程技术为核心，帮助牧场梳理和搭建符合牧场实际情况的技术和管理体系；为牧场持续的引进先进的管理方法和技术；利用自牧天成牧场数据化管理平台为牧场提供量化的，精细化的分析，从而实现节本增效。
              </div>
            </div>
            <div class="system-bottom">
              <div class="system-boimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20356%281%29.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="system-rightbox">
            <div class="system-top">
              <div class="system-imgbox">
                <div class="system-imon">
                  <img
                    src="https://qncdn.uni-sheep.com/s/image%20107%281%29.png"
                    alt=""
                  />
                </div>
                <div class="system-tin family-picturetext">
                  数据流程服务体系
                </div>
              </div>
              <div class="system-cont family-about">
                指数据顾问将我们制定的业务流程因时制宜，因地制宜地导入到各个客户牧场。通过流程设计和管理，来约束和指导牧场每日的工作安排；同时根据数据分析和现场调研，发现牧场的问题；协助牧场跟踪落实，并且利用数据分析，跟踪量化的结果变化，提出具体的服务内容和项目。
              </div>
            </div>
            <div class="system-bottom">
              <div class="system-boimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20357%20%282%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务流程 -->
      <div class="flowpath-box" v-if="flowpathdisplay">
        <div class="flowpath-content">
          <div class="flowpath-titbox">
            <div class="flowpath-futit family-title">服务流程</div>
            <div class="flowpath-img">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20323%281%29.png"
                alt=""
              />
            </div>
          </div>
          <div class="flowpath-miaoshu family-about">
            咨询服务先从基础调研开始，在完成牧场硬件设施、羊群结构、日粮结构和营养指标等基础性调研后，同时开始完成牧场的业务流程再造和现场技术指导。我们以技术提升为本，以流程提升带动管理提升为纲，使得牧场从短期效益到长期发展都得到满足。我们会为每一个牧场，建立一个专业的固定团队，对客户牧场进行长期的跟踪服务，有效保证了所有服务方案的针对性和有效性。
          </div>
        </div>
      </div>
      <!-- 效果评估 -->
      <div class="evaluation-box">
        <div class="evaluation-cont" v-if="evaluationdisplay">
          <div class="evaluation-top">
            <div class="evaluation-bao">
              <div class="evaluation-futit family-title">效果评估</div>
              <div class="evaluation-img">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20323%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="evaluation-name family-text">
              让客户盈利是咨询服务的主要目标
            </div>
          </div>
          <div class="evaluation-bottom">
            <div class="evaleftbutton" @click="prevImage">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20341%20%281%29%281%29.png"
                alt=""
              />
            </div>
            <div class="eva-centerbox">
              <transition name="fade" mode="out-in">
                <img
                  :src="currentImage"
                  :key="currentImage"
                  alt="Slideshow Image"
                />
              </transition>
            </div>
            <div class="evaleftbutton" @click="nextImage">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20342%20%281%29%281%29.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 收费标准 -->
      <div class="standards-box">
        <div class="standards-cont" v-if="standardsdisplay">
          <div class="service-titbox">
            <div class="standards-futit family-title">收费标准</div>
            <div class="standards-img">
              <img
                src="https://qncdn.uni-sheep.com/s/Group%20323%281%29.png"
                alt=""
              />
            </div>
          </div>
          <div class="standards-center">
            <div class="standards-left">
              <div class="standards-img">
                <img
                  src="https://qncdn.uni-sheep.com/s/08983e46d05d2b02882a81a85631bcec54387e9120a21-oPcclH_fw1200%201%281%29.png"
                  alt=""
                />
              </div>
              <div class="standards-ti family-picturetext">
                牧场技术服务体系
              </div>
              <div class="standards-fu family-text">
                服务费为100元/只/年；不但全面包含数据化服务，每周例会，同时包括每月一次的专家登门服务
              </div>
            </div>
            <div class="standards-right">
              <div class="standards-rimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/image%2098%281%29.png"
                  alt=""
                />
              </div>
              <div class="standards-rti family-picturetext">
                数据流程服务体系
              </div>
              <div class="standards-rfu family-text">
                服务费为50元/只/年，旨在帮助客户建立起相关的业务流程管理，制定合理的业务考核指标，安装导入牧场管理系统，并且每月提供专业的分析报表
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 公共脚步部分 -->
      <public-footer @Immediately="Immediately"></public-footer>
    </div>
  </div>
</template>

<script>

import PublicFooter from "../../../components/publicfooter/index.vue";

export default {
  metaInfo: {
    title: '咨询服务-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  components: {
    PublicFooter,
  },
  props: [],
  data() {
    return {
      // 效果评估
      images: [
        "https://qncdn.uni-sheep.com/s/Group%20427318825.png",
        "https://qncdn.uni-sheep.com/s/Group%20390%281%29.png",
      ],
      // 当前显示图片的索引
      currentIndex: 0,
      // 服务体系动画显示
      servicedisplay: false,
      // 服务流程动画显示
      flowpathdisplay: false,
      // 效果评估动画显示
      evaluationdisplay: false,
      // 收费标准动画显示
      standardsdisplay: false,
    };
  },
  methods: {
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      this.scrollTop = event.target.scrollTop;
      // console.log(this.scrollTop, "ddddd");
      const topObj = {
        80: "servicedisplay",
        700: "flowpathdisplay",
        1400: "evaluationdisplay",
        1900: "standardsdisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
  },
  created() {
    this.listenerFunction();
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  distroyed() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
};
</script>
<style scoped lang="scss">


//公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.seebabox {
  width: 100%;
  height: 100%;
  // 奥宸咨询
  .seekbox {
    width: 100%;
    height: 49.625rem;
    display: flex;
    .seek-liftbox {
      width: 55%;
      height: 100%;
      position: relative;
      .bt-img {
        position: absolute;
        width: 18.75rem;
        height: 25rem;
        bottom: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .seek-content {
        position: absolute;
        width: 39.375rem;
        height: 25.125rem;
        right: 0rem;
        top: 12.0625rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .seek-title {
          font-size: 2.5rem;
        }
        .seek-nei {
          width: 100%;
          height: 21.25rem;
          font-size: 1.125rem;
          line-height: 2.2rem;
          color: #353a67;
          text-align: justify;
        }
      }
      .seek-limg {
        width: 100%;
        height: 37.125rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .seek-rightbox {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      .seekimg {
        width: 45rem;
        height: 30.8125rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // 服务体系
  .service-box {
    width: 80rem;
    height: 38rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: slide-up 1.5s ease-in-out;
    animation-fill-mode: forwards;
    .service-titbox {
      width: 10rem;
      height: 3rem;
      margin: 0 auto;
      font-size: 2.25rem;
      text-align: center;
      display: flex;
      align-items: center;
      position: relative;
      .service-futit {
        position: absolute;
        z-index: 1;
      }
      .service-img {
        width: 3.34875rem;
        height: 2.50375rem;
        position: absolute;
        left: 7.5rem;
        top: 0.25rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .scriptbox {
      width: 100%;
      height: 4rem;
      .script-content {
        font-size: 1.125rem;
        line-height: 2.5rem;
        color: #353a67;
        text-align: center;
      }
    }
    .system-box {
      width: 100%;
      height: 26rem;
      border-radius: 0.5625rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .system-leftbox {
        width: 40%;
        height: 22rem;
        background: white;
        border-radius: 0.5625rem;
        padding-left: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 1.0625rem 0.75rem #f1f2f9;
        .system-top {
          width: 28rem;
          height: 20rem;
          .system-imgbox {
            width: 20rem;
            height: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.1rem;
            .system-imon {
              width: 5.5rem;
              height: 4.5rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .system-tin {
              font-size: 1.6rem;
              color: #333333;
            }
          }
          .system-cont {
            padding-top: 0.5rem;
            width: 100%;
            height: 12.5rem;
            font-size: 1.125rem;
            line-height: 2.2rem;
            color: #353a67;
          }
        }
        .system-bottom {
          width: 100%;
          height: 4rem;
          display: flex;
          justify-content: flex-end;
          .system-boimg {
            width: 4rem;
            height: 4rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .system-rightbox {
        width: 40%;
        height: 22rem;
        background: white;
        border-radius: 0.5625rem;
        padding-left: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 0 1.0625rem 0.75rem #f1f2f9;
        .system-top {
          width: 28rem;
          height: 20rem;
          margin-top: 1rem;
          .system-imgbox {
            width: 20rem;
            height: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .system-imon {
              width: 4rem;
              height: 4rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .system-tin {
              font-size: 1.6rem;
              color: #333333;
            }
          }
          .system-cont {
            padding-top: 0.5rem;
            width: 100%;
            height: 12.5rem;
            font-size: 1.125rem;
            line-height: 2.2rem;
            color: #353a67;
          }
        }
        .system-bottom {
          width: 100%;
          height: 4rem;
          display: flex;
          justify-content: flex-end;
          .system-boimg {
            width: 4rem;
            height: 4rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  // 服务流程
  .flowpath-box {
    width: 100%;
    height: 52rem;
    margin: auto;
    background: url("https://qncdn.uni-sheep.com/s/%E6%9C%8D%E5%8A%A1%E6%B5%81%E7%A8%8B%E5%9B%BE%281%29.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 4rem;
    animation: slide-up 1.5s ease-in-out;
    animation-fill-mode: forwards;
    .flowpath-content {
      width: 80rem;
      height: 20rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .flowpath-titbox {
        width: 10rem;
        height: 3rem;
        margin: 0 auto;
        font-size: 2.25rem;
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 15px;
        .flowpath-futit {
          position: absolute;
          z-index: 1;
        }
        .flowpath-img {
          width: 3.34875rem;
          height: 2.87875rem;
          position: absolute;
          left: 7.5rem;
          top: 0.25rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .flowpath-miaoshu {
        width: 100%;
        height: 8rem;
        font-size: 1rem;
        font-weight: 800;
        line-height: 2.2rem;
        color: #353a67;
      }
    }
  }
  // 效果评估
  .evaluation-box {
    width: 100%;
    height: 38rem;
    .evaluation-cont {
      width: 80rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .evaluation-top {
        width: 16rem;
        height: 4.5rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4.5rem;
        .evaluation-bao {
          width: 100%;
          height: 100%;
          font-size: 2.25rem;
          text-align: center;
          position: relative;
          .evaluation-futit {
            position: absolute;
            z-index: 1;
            left: 3.2rem;
          }
          .evaluation-img {
            width: 3.34875rem;
            height: 2.87875rem;
            position: absolute;
            left: 10.5rem;
            top: -0.1rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .evaluation-name {
          font-size: 1rem;
          color: #353a67;
          margin-left: 0.5rem;
        }
      }
      .evaluation-bottom {
        width: 100%;
        height: 24rem;
        background: white;
        margin-top: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .evaleftbutton {
          width: 38px;
          height: 36.5px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            transition: opacity 0.3s;
          }
        }
        .evaleftbutton:hover {
          background: rgba(0, 0, 255, 0.836);
          border-radius: 50%;
          box-shadow: 0 0 0.25rem rgba(0, 0, 255, 0.836);
        }
        .eva-centerbox {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  // 收费标准
  .standards-box {
    width: 100%;
    height: 28rem;
    .standards-cont {
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      width: 1005px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .service-titbox {
        width: 10rem;
        height: 3rem;
        margin: 0 auto;
        font-size: 2.25rem;
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;
        .standards-futit {
          position: absolute;
          z-index: 1;
        }
        .standards-img {
          width: 3.34875rem;
          height: 2.87875rem;
          position: absolute;
          left: 7.5rem;
          top: 0.25rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .standards-center {
        width: 100%;
        height: 22rem;
        display: flex;
        justify-content: space-between;
        .standards-left {
          width: 39%;
          height: 18rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .standards-img {
            width: 13.56rem;
            height: 9.37rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .standards-ti {
            font-size: 1.25rem;
          }
          .standards-fu {
            font-size: 1.0625rem;
            color: #353a67;
            text-align: center;
            line-height: 1.5rem;
          }
        }
        .standards-right {
          width: 39%;
          height: 19rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .standards-rimg {
            width: 16.06rem;
            height: 9.37rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .standards-rti {
            font-size: 1.25rem;
          }
          .standards-rfu {
            font-size: 1.0625rem;
            color: #353a67;
            line-height: 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
